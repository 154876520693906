<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Reimbursement">
      <template #page-header>
        <h2 class="page-title">Reimbursement</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <RequestHeader
          :search="true"
          @addNew="openModalRequest"
          :lodaData="isReimbursementPersonalLoading"
          @onSearch="searchIconClick($event)"
        ></RequestHeader>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="reimbursementPersonal"
            :per-page="perPage"
            :loading="isReimbursementPersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="reimburse_type_name"
                label="Type of Reimburse"
                v-slot="props"
                sortable
                width="25%"
              >
                <span class="is-capitalize">
                  {{ props.row.reimburseTypeName }}
                </span>
              </b-table-column>

              <b-table-column field="date" label="Date" v-slot="props" sortable>
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="total_reimburse"
                label="Reimbursement Amount"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{
                    props.row.totalReimburse &&
                    `Rp ${props.row.totalReimburse.toLocaleString()}`
                  }}
                </span>
              </b-table-column>

              <b-table-column
                field="reason"
                label="Reason"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="created_at"
                label="Created At"
                v-slot="props"
                sortable
                width="12%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(reimbursementPersonal[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Reimbursement Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Reimburse Type</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedColumn.reimburseTypeName }}
                {{ selectedColumn.reimburseTypeId }}
              </div>
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.date) }}
              </div>
              <div class="column is-4">Amount</div>
              <div class="column is-8 has-text-weight-bold">
                {{ 'Rp. ' + selectedColumn.totalReimburse }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-4" v-if="selectedColumn.files.length > 0">
                Files
              </div>
              <div class="column is-8" v-if="selectedColumn.files.length > 0">
                <div class="tags">
                  <span
                    v-for="file in selectedColumn.files"
                    :key="file.id"
                    class="tag is-primary tag-small"
                  >
                    <div class="file-tag" @click="openFileUrl(file.url)">
                      {{ file.name }}
                    </div>
                  </span>
                </div>
              </div>
              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.requestReason }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="
                  selectedColumn.status === 'waiting' ||
                  selectedColumn.status === 'approved'
                "
              >
                <b-button
                  expanded
                  @click="cancelReimbursement(selectedColumn.id)"
                >
                  Cancel Request
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <ReimbursementPersonalModal
          :show="isModalOpen"
          :formData="form"
          :reimbursementTypeList="reimbursementTypeList"
          :loadingSubmit="isSubmitting"
          :isEditing="isEditing"
          :listUser="listUser"
          :isFetchingUser="isFetchingUser"
          :notUpdatable="notUpdatable"
          :selectedUser="selectedUser"
          :locale="locale"
          :maxDate="maxDate"
          :tempFiles="tempFiles"
          @hide="closeModalRequest"
          @submit="submit"
          @getUserList="getUserList($event)"
          @selectUser="selectUser($event)"
          @getMoreUserList="getMoreUserList"
          @openFileUrl="openFileUrl"
          @deleteDropFile="deleteDropFile"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { determineFontColorByStatus, showToast } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'
import isNotUpdatable from '../Script/isNotUpdatable'
import debounce from 'lodash/debounce'
import ApiService from '../../../services/common/api.service'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import ReimbursementPersonalModal from './ReimbursementPersonalModal.vue'

export default {
  components: {
    TimeOffApprovalHistoryComponent,
    RequestHeader,
    ReimbursementPersonalModal,
  },
  data() {
    const today = new Date()
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      isReimbursementPersonalLoading: false,
      isReimbursementTypeLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      notUpdatable: null,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      form: {
        id: null,
        reimburse_type_id: null,
        date: null,
        total_reimburse: null,
        files: [],
        request_reason: null,
        status: null,
        additionalApproverId: null,
      },

      tempFiles: null,

      attendanceOptions: [],
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,
      locale: 'en-GB',

      selectedUser: null,
      listUser: [],
      isFetchingUser: false,
      pageUserList: 1,
      lastPageUserlist: 1,
      searchUser: null,
      windowHeight: window.innerHeight,
      size: 900,
    }
  },
  computed: {
    ...mapGetters({
      reimbursementPersonal: 'reimbursement/getReimbursementRequestData',
      reimbursementTypeList: 'reimbursement/getReimbursementTypeData',
      currentUser: 'auth/currentUser',
      reimbursementDetail: 'reimbursement/getReimbursementDetail',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadReimbursementPersonal:
        'reimbursement/fetchReimbursementRequestData',
      saveReimbursement: 'reimbursement/saveReimbursement',
      updateReimbursement: 'reimbursement/updateReimbursementRequest',
      actionLoadReimbursementType: 'reimbursement/fetchReimbursementTypeData',
      actionLoadReimbursementDetail: 'reimbursement/fetchReimbursementDetail',
    }),

    ...mapMutations({
      setReimbursementPersonal: 'reimbursement/setReimbursementRequestData',
      setReimbursementDetail: 'reimbursement/setReimbursementDetail',
    }),

    /**
     * Open Request Modal. If row present,
     * set form to include data from selected table row
     * @param {integer} row - table row index (optional)
     */
    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.tempFiles = { ...row.files }
        this.form = {
          id: row.id,
          reimburse_type_id: row.reimburseTypeId,
          date: new Date(row.date),
          files: [],
          request_reason: row.requestReason,
          total_reimburse: row.totalReimburse,
          status: row.status,
        }
        this.selectedUser =
          row.history.length > 0 ? row.history[0].fullName : '-' || '-'
        this.notUpdatable = isNotUpdatable.check(row.status, row.history)
      }
      this.isModalOpen = true
    },

    /**
     * Close request modal
     */
    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    /**
     * Open File on mouse click
     * @param {string} fileUrl - File url from database
     */
    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    /**
     * Delete image file
     * @param {integer} index - file index
     */
    deleteDropFile(index) {
      this.form.files.splice(index, 1)
    },

    /**
     * Submit form data.
     */
    async submit() {
      const form = new FormData()

      form.append('date', moment(this.form.date).format('YYYY-MM-DD'))
      form.append('reimburse_type_id', this.form.reimburse_type_id)
      form.append('total_reimburse', this.form.total_reimburse)
      form.append('request_reason', this.form.request_reason)

      if (!this.isEditing) {
        form.append('additional_approver_id', this.form.additionalApproverId)
        if (!this.form.additionalApproverId) {
          form.delete('additional_approver_id')
        }
      }

      this.isSubmitting = true
      if (!this.isEditing) {
        try {
          if (this.form.files) {
            this.form.files.forEach((files) => {
              form.append('images[]', files)
            })
          }

          await this.saveReimbursement(form)
          showToast('Data is successfully added!', 'is-success', 'is-top')
          this.closeModalRequest()
        } catch (e) {
          showToast(e.response.data.message, 'is-danger', 'is-top')
        }
      } else {
        try {
          if (this.form?.files) {
            this.form.files.forEach((files) => {
              form.append('images[]', files)
            })
          }
          const data = { id: this.form.id, form }

          await this.updateReimbursement(data)
          showToast('Data is successfully updated!', 'is-success', 'is-top')
          this.closeModalRequest()
        } catch (e) {
          showToast(e.response.data.message, 'is-danger', 'is-top')
        }
      }

      this.isSubmitting = false
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {dateString} str - date string
     * @return {dateString} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Load more data of reimbursement Personal list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadReimbursementPersonalList()
        this.isLoadMore = false
      }
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setReimbursementPersonal([])
      await this.loadReimbursementPersonalList(searchInput)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadReimbursementPersonalList()
    },

    /**
     * Reset form when close/ hide
     */
    resetForm() {
      this.form = {
        id: null,
        reimburse_type_id: null,
        date: null,
        total_reimburse: null,
        files: [],
        request_reason: null,
        additionalApproverId: null,
      }
      this.selectedUser = null
      this.searchUser = null
      this.tempFiles = null
    },

    /**
     * Load Reimbursement Personal List data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadReimbursementPersonalList(searchInput) {
      this.isReimbursementPersonalLoading = true

      try {
        const response = await this.actionLoadReimbursementPersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementPersonalLoading = false
    },

    /**
     * Load Reimbursement Type dropdown list
     */
    async loadReimbursementTypeList() {
      this.isReimbursementTypeLoading = true

      try {
        await this.actionLoadReimbursementType({
          perPage: 1000,
          page: 1,
          usage: 'modal',
        })
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementTypeLoading = false
    },

    /**
     * Open Sidebar content from table
     * @param {Object} reimburse - selected reimburse data from table row
     * @param {integer} id - selected data id
     */
    async openSidebar(reimburse, id) {
      if (id) {
        let response = await this.actionLoadReimbursementDetail(id)
        this.selectedColumn = response.data.data
      } else {
        this.selectedColumn = reimburse
      }
      this.isRightBarOpen = true
    },

    /**
     * Close Sidebar
     */
    closeSidebar() {
      this.isRightBarOpen = false
    },

    /**
     * Determine Profile picture from database
     * @param {Array} user - user info that is currently logged in
     * @return {String} user profile picture url
     */
    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    /**
     * Select User from PIC database
     * @param {Object} option - selected user object (optional)
     */
    selectUser(option) {
      if (option) {
        this.selectedUser = option.fullname
        this.form.additionalApproverId = option.id
      }
    },

    /**
     * Get User list from database
     * @return {Object} listUser - User List
     */
    getUserList: debounce(function (name) {
      this.form.additionalApproverId = null
      if (this.selectedUser !== name) {
        this.selectedUser = name
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserlist = 1
      }
      if (!name.length) {
        this.listUser = []
        this.pageUserList = 1
        this.lastPageUserList = 1
      }
      this.isFetchingUser = true

      ApiService.get('/api/space-roketin/user/v3', {
        search: this.selectedUser,
        page: this.pageUserList,
      })
        .then((response) => {
          response.data.data.forEach((item) => this.listUser.push(item))
          this.pageUserList++
          this.lastPageUserList = response.data.meta.lastPage
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isFetchingUser = false
        })
    }, 500),

    /**
     * Get more user list when auto complete scrolled
     * @return {Object} listUser - User List
     */
    getMoreUserList: debounce(function () {
      this.getUserList(this.selectedUser)
    }, 250),

    /**
     * Cancel Reimbursement Request
     * @param {integer} id - Request Id
     */
    async cancelReimbursement(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          const data = { id, form }
          await this.updateReimbursement(data)
          showToast('Request canceled', 'is-success', 'is-top')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-top')
        }
      }
    },
  },
  async mounted() {
    this.setReimbursementPersonal([])
    await this.loadReimbursementPersonalList()
    await this.loadReimbursementTypeList()
    if (this.$route.query.id) {
      await this.openSidebar(null, this.$route.query.id)
    }
    window.onresize = () => {
      this.windowHeight = window.innerHeight
    }
  },
}
</script>
