const isNotUpdatable = {
  check(status, history) {
    let check = false

    // if request status is waiting then check approval
    if (status === 'waiting') {
      for (let index in history) {
        // if there is approved approval then not updatable
        if (history[index].status === 'approved') {
          return true
        }
      }
    }

    // if request status is not waiting then not updatable
    if (status !== 'waiting') {
      check = true
    }

    return check
  },
}

export default isNotUpdatable
