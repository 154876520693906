<template>
  <Modal
    :title="'Request Reimbursement'"
    :show="show"
    :class="'modal-allowed-capacity'"
    :isCancel="false"
    :isSubmit="false"
    @hide="hide"
    @onSubmit="onSubmit"
  >
    <template #form>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="is-label-14px-normal form-timeoff-type"
        >
          <div class="columns is-multiline">
            <div class="column is-12">
              <ValidationProvider
                name="reimburse type"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Reimbursement Type"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-select
                    v-model="formData.reimburse_type_id"
                    expanded
                    placeholder="Reimbursement Type"
                    class="hover"
                    :disabled="isEditing && notUpdatable"
                  >
                    <option
                      v-for="option in reimbursementTypeList"
                      :value="option.id"
                      :key="'attendance-' + option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="transaction date"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Transaction Date"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-datepicker
                    placeholder="Date"
                    icon-right="calendar"
                    id="date-picker-calendar"
                    :max-date="maxDate"
                    :locale="locale"
                    v-model="formData.date"
                    class="attendance-datepicker"
                    :disabled="isEditing && notUpdatable"
                  ></b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="total reimburse"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Amount"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <currency-input
                    v-model="formData.total_reimburse"
                    placeholder="Amount"
                    currency="IDR"
                    locale="id"
                    class="currency-input"
                    :allow-negative="false"
                    :precision="{ min: 0, max: 0 }"
                    :distraction-free="false"
                    :disabled="isEditing && notUpdatable"
                  ></currency-input>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12 request-section-radio-tooltip">
              <div v-if="!isEditing && selectedUser">
                <b-field label="PIC">
                  <p>
                    <b-icon icon="account" class="approver-icon mr-2" />
                    {{ selectedUser }}
                  </p>
                </b-field>
              </div>
            </div>

            <div
              class="column is-12"
              v-if="!isEditing || formData.status === 'waiting'"
            >
              <ValidationProvider
                name="Files"
                v-slot="{ errors }"
                :rules="tempFiles && tempFiles.length !== 0 ? '' : 'required'"
              >
                <b-field
                  label="Upload File (Maximum File size must be 5 MB Per file)"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-upload
                    v-model="formData.files"
                    accept="image/*,,application/pdf"
                    multiple
                    drag-drop
                    expanded
                    @input="uploadFile"
                    :disabled="isEditing && notUpdatable"
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="image" size="is-large"></b-icon>
                        </p>
                        <p>
                          Drag image here or
                          <span class="has-red-text">Browse</span>
                        </p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
                <div class="reimbursement-upload-note-normal">
                  *File: PDF, JPG, PNG, WEBP
                </div>

                <div
                  v-if="errorFiles.length > 0"
                  class="reimbursement-upload-note-error"
                >
                  {{
                    `${errorFiles
                      .map((item) => item)
                      .join(', ')} file size is more than 5mb`
                  }}
                </div>
              </ValidationProvider>
            </div>
            <div
              class="column is-12 has-text-weight-bold"
              v-if="formData.files.length !== 0"
            >
              File to Upload
            </div>
            <div
              class="column is-12 file-upload-overflow"
              v-if="formData.files.length !== 0"
            >
              <div class="tags" v-if="formData.files.length !== 0">
                <span
                  v-for="(file, index) in formData.files"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                  <button
                    class="delete is-small"
                    type="button"
                    @click="deleteDropFile(index)"
                  ></button>
                </span>
              </div>
            </div>

            <div
              class="column is-12 has-text-weight-semibold request-section-radio-tooltip"
              v-if="tempFiles && tempFiles.length !== 0"
            >
              <b-tooltip position="is-top" class="reimbursement-tooltip-file">
                <b-icon
                  icon="information"
                  size="is-small"
                  class="tooltip-date-icon"
                />
                <template v-slot:content>
                  <div class="is-flex">
                    Due to security concerns, the file that you have uploaded
                    before will be deleted if you uploaded a new file. Please
                    reupload.
                  </div>
                </template>
              </b-tooltip>
              File Uploaded
            </div>
            <div
              class="column is-12 file-upload-overflow"
              v-if="
                tempFiles &&
                tempFiles.length !== 0 &&
                formData.files.length === 0
              "
            >
              <div
                class="tags"
                v-if="
                  tempFiles &&
                  tempFiles.length !== 0 &&
                  formData.files.length === 0
                "
              >
                <span
                  v-for="(file, index) in tempFiles"
                  :key="index"
                  class="tag is-primary"
                >
                  {{ file.name }}
                </span>
              </div>
            </div>

            <div class="column is-12">
              <ValidationProvider
                name="request reason"
                v-slot="{ errors }"
                rules="required"
              >
                <b-field
                  label="Request Reason"
                  class="is-required"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                >
                  <b-input
                    type="textarea"
                    v-model="formData.request_reason"
                    placeholder="Please write your message"
                    :disabled="isEditing && notUpdatable"
                    @input="stripSystemEmojis"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="column is-12">
              <b-button
                expanded
                type="is-primary"
                :loading="loadingSubmit"
                :disabled="isEditing && notUpdatable"
                native-type="submit"
              >
                {{ isEditing ? 'Update' : 'Request Reimbursement' }}
              </b-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Modal>
</template>

<script>
// components
import Modal from '../../../components/Modals/Modal.vue'

export default {
  components: { Modal },

  data() {
    return {
      errorFiles: [],
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    loadingSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    notUpdatable: {
      type: Boolean,
      default: false,
    },
    listUser: {
      type: Array,
      default: null,
    },
    isFetchingUser: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: '',
    },
    maxDate: {
      type: Date,
      default: null,
    },
    tempFiles: {
      type: Object,
      default: null,
    },
    reimbursementTypeList: {
      type: Array,
      default: null,
    },
  },

  methods: {
    /**
     * Handle upload files.
     * @param {Array} files - list of uploaded files
     */
    uploadFile(files) {
      // Maximum size for uploaded file (5mb)
      const requirementSize = 5242880

      let notValidatedFileNames = files
        .filter((item) => item.size > requirementSize)
        .map((file) => file.name)

      this.errorFiles = notValidatedFileNames

      const validatedFiles = files.filter((item) => item.size < requirementSize)
      this.formData.files = validatedFiles
    },
    /**
     * Emit hide modal component.
     */
    hide() {
      this.errorFiles = []
      this.$emit('hide')
    },

    /**
     * Emit Submit modal.
     */
    onSubmit() {
      this.$emit('submit')
    },

    /**
     * Emit fetch user list.
     */
    getUserList(event) {
      this.$emit('getUserList', event)
    },

    /**
     * Emit select user.
     * @param event - event listener
     */
    selectUser(event) {
      this.$emit('selectUser', event)
    },

    /**
     * Emit get more user list.
     */
    getMoreUserList() {
      this.$emit('getMoreUserList')
    },

    /**
     * Emit open file url.
     */
    openFileUrl(fileUrl) {
      this.$emit('openFileUrl', fileUrl)
    },

    /**
     * Emit delete drop file.
     */
    deleteDropFile() {
      this.$emit('deleteDropFile')
    },
    /**
     * Prevent Emoji Input.
     */
    stripSystemEmojis(event) {
      const regex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
      const checker = event.match(regex)
      if (checker) {
        checker.forEach((val) => {
          this.$nextTick(() => {
            this.$set(
              this.formData,
              'request_reason',
              this.formData.request_reason.replace(val, '')
            )
          })
        })
      }
    },
  },
}
</script>
